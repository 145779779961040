
const digest = '5472e520aa0ac67e49b0554c14abf1367d472b9003fb079672d09efc346b1920';
const css = `._datePicker_1hqkh_1 {
  z-index: 5;
  margin: 0 0.25rem;
}

._chartDescription_1hqkh_6 li {
  padding-left: 0.125rem;
  margin-left: 1rem;
  list-style-type: disc;
}

._filter_1hqkh_12 {
  min-width: 12rem;
}

._tooltipIcon_1hqkh_16 {
  height: 16px;
  width: 16px;
}

._exportButton_1hqkh_21 {
  margin-left: auto;
  margin-right: 0rem;
}

.highcharts-xaxis-labels a:hover {
  text-decoration: underline;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"datePicker":"_datePicker_1hqkh_1","chartDescription":"_chartDescription_1hqkh_6","filter":"_filter_1hqkh_12","tooltipIcon":"_tooltipIcon_1hqkh_16","exportButton":"_exportButton_1hqkh_21"};
export { css, digest };
  