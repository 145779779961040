
const digest = '72ed035994fc95bd06146fa9d41ba7cf69d80734e9bb500378304e15227d3e16';
const css = `._card_rrs2e_1 {
  background: #fff;
  border: 1px solid #e7e7ed;
}

._withPadding_rrs2e_6 {
  padding: 1rem;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"card":"_card_rrs2e_1","withPadding":"_withPadding_rrs2e_6"};
export { css, digest };
  