
const digest = '0297d8beb369dfbc690aa50ca5897f06b00c077ac1925e0f608445a3810d32ec';
const css = `._column_1xzvz_1 {
  max-width: 14rem;
  text-transform: none;
  font-size: 0.875rem;
  font-weight: normal;
  margin: 0.5rem;
}

._iconWrapper_1xzvz_9 {
  position: relative;
}

._icon_1xzvz_9 {
  position: absolute;
  top: 0;
  transform: translateY(10%);
  left: 0.25rem;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"column":"_column_1xzvz_1","iconWrapper":"_iconWrapper_1xzvz_9","icon":"_icon_1xzvz_9"};
export { css, digest };
  