
const digest = 'ba45d3e2eaaa3d81d0b998c01e99590b26a6cac2130c721fba9181b0e81d7bb1';
const css = `._label_1ibpg_1 {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: 1rem;
}

._checkbox_1ibpg_8 {
  position: relative;
  /* border: 1px solid var(--color--neutral-20); */

  border-radius: 4px;
  line-height: 1;
  margin-right: 1rem;
}

._checkbox_1ibpg_8:before {
  position: absolute;
  top: -2px;
  left: -2px;

  background-color: white;
  border: 1px solid var(--color--neutral-10);
  border-radius: 4px;
  content: "";
  cursor: pointer;
  display: block;
  height: 16px;
  width: 16px;
}

._checkbox_1ibpg_8:checked:before {
  background: var(--color-blue);
  border: solid var(--color-blue);
}

._checkbox_1ibpg_8:checked:after {
  position: absolute;
  top: 0;
  left: 3px;

  border: solid var(--color-white);
  border-radius: 1px;
  border-width: 0 3px 3px 0;
  content: "";
  display: block;
  height: 10px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 6px;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"label":"_label_1ibpg_1","checkbox":"_checkbox_1ibpg_8"};
export { css, digest };
  